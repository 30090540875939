import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";
import NewsFiler from "../../components/newsFilter";
import Archive from "../../components/archive";

const Blog = ({ data: { lastPostsNews, lastPostsInfo, archiveInfo } }) => {
  const lang = "de";

  return (
    <Layout
      seoTitle="Aktuelles"
      lang={lang}
      translationPL="/aktualnosci/"
      translationEN="/en/news/"
    >
      <SmallBanner title="Aktuelles" lang={lang} />
      <NewsFiler
        lastPostsNews={lastPostsNews}
        lastPostsInfo={lastPostsInfo}
        lang={lang}
      />
      <Archive posts={archiveInfo} lang={lang} />
    </Layout>
  );
};

export default Blog;

export const query = graphql`
  query {
    lastPostsNews: allWpPost(
      sort: { fields: date, order: DESC }
      filter: {
        language: { code: { eq: DE } }
        categories: { nodes: { elemMatch: { name: { eq: "Wydarzenia" } } } }
      }
      limit: 3
    ) {
      edges {
        node {
          id
          slug
          uri
          title
          content
          excerpt
          date
          language {
            code
            slug
            name
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
    }
    lastPostsInfo: allWpPost(
      sort: { fields: date, order: DESC }
      filter: {
        language: { code: { eq: DE } }
        categories: { nodes: { elemMatch: { name: { eq: "Ogłoszenia" } } } }
      }
      limit: 3
    ) {
      edges {
        node {
          id
          slug
          uri
          title
          content
          excerpt
          date
          language {
            code
            slug
            name
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
    }
    archiveInfo: allWpPost(
      sort: { fields: date, order: DESC }
      filter: { language: { code: { eq: DE } } }
      skip: 3
    ) {
      edges {
        node {
          id
          slug
          uri
          title
          content
          excerpt
          date
          language {
            code
            slug
            name
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
    }
  }
`;
